import * as React from 'react';
import { useStyles } from '../styles';
import { RendererProps } from '../renderers';

export function RenderBoolean(props: RendererProps) {
    const styles = useStyles();

    if (typeof props.value === 'boolean') {
        return (
            <span style={styles.boolean}>{props.value ? 'true' : 'false'}</span>
        );
    }

    return null;
}
