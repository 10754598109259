import { PropsWithChildren } from 'react';
import { useStyles } from '@tdc-cl/react-object-renderer';
import React from 'react';

export function Tooltip(
    props: PropsWithChildren<{ message: string; disabled?: boolean }>
) {
    const styles = useStyles();

    if (props.disabled) {
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return (
        <abbr title={props.message} style={styles.error}>
            {props.children}
        </abbr>
    );
}
