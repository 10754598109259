import React from 'react';
import { createContext, PropsWithChildren, useContext } from 'react';
import { Failure, StructError } from 'superstruct';

export function FailuresProvider(
    props: PropsWithChildren<{ error: StructError }>
) {
    return (
        <FailuresContext.Provider value={props.error.failures()}>
            {props.children}
        </FailuresContext.Provider>
    );
}

export function useFailures() {
    return useContext(FailuresContext);
}

const FailuresContext = createContext<Failure[]>(null!);
