import * as React from 'react';
import { createContext, PropsWithChildren, useContext } from 'react';
import _ from 'lodash';
import { useStyles } from '../styles';
import { RendererProps } from '../renderers';
import { useComponents } from '../components';

export function RenderObject(props: RendererProps) {
    const components = useComponents();
    const styles = useStyles();

    if (_.isObject(props.value) && !Array.isArray(props.value)) {
        const entries = Object.entries(props.value);
        return (
            <components.ObjectContainer>
                {entries.map(([key, value], index) => (
                    <EntryContext.Provider
                        value={{ key, value, index }}
                        key={key}
                    >
                        <components.NewLine tabs={props.tabs + 1} />
                        <components.Entry />
                        {index !== entries.length - 1 && (
                            <span style={styles.punctuation}>, </span>
                        )}
                    </EntryContext.Provider>
                ))}
            </components.ObjectContainer>
        );
    }

    return null;
}

export function EntryProvider(
    props: PropsWithChildren<{
        entry: { key: string; value: unknown; index: number };
    }>
) {
    return (
        <EntryContext.Provider value={props.entry}>
            {props.children}
        </EntryContext.Provider>
    );
}

export function useEntry() {
    return useContext(EntryContext);
}

const EntryContext = createContext<{
    key: string;
    value: unknown;
    index: number;
}>(null!);
