import { explain } from './utils';
import {
    useComponents,
    useEntry,
    useStack,
} from '@tdc-cl/react-object-renderer';
import { useFailures } from './failures';
import { Tooltip } from './Tooltip';
import React from 'react';
import _ from 'lodash';

export function Entry() {
    const components = useComponents();
    const failures = useFailures();
    const stack = useStack();
    const entry = useEntry();
    const entryPath = [...stack.path, entry.key];
    const failure = failures.find(failure =>
        _.isEqual(entryPath, failure.path)
    );

    return (
        <Tooltip
            message={explain(failure)}
            disabled={failure?.type !== 'never'}
        >
            <components.Key />
            <components.Colon />
            <Tooltip
                message={explain(failure)}
                disabled={!failure || failure.type === 'never'}
            >
                <components.Value />
            </Tooltip>
        </Tooltip>
    );
}
