import * as React from 'react';
import { Recursive } from './utils';
import { useStyles } from './styles';

export interface RenderProps {
    value: unknown;
}

export function Render(props: RenderProps) {
    const styles = useStyles();

    return (
        <pre style={styles.container}>
            <Recursive value={props.value} tabs={0} path={[]} />
        </pre>
    );
}
