import * as React from 'react';
import { useStyles } from '../styles';
import { RendererProps } from '../renderers';

export function RenderNumber(props: RendererProps) {
    const styles = useStyles();

    if (typeof props.value === 'number') {
        return <span style={styles.number}>{props.value}</span>;
    }

    return null;
}
