import * as React from 'react';
import { Recursive } from '../utils';
import _ from 'lodash';
import { useStyles } from '../styles';
import { RendererProps } from '../renderers';
import { useComponents } from '../components';

export function RenderArray(props: RendererProps) {
    const components = useComponents();
    const styles = useStyles();

    if (Array.isArray(props.value)) {
        const array = props.value;
        return (
            <React.Fragment>
                <span style={styles.punctuation}>[</span>
                {array.map((element, index) => (
                    <React.Fragment key={index}>
                        <components.NewLine tabs={props.tabs + 1} />
                        <Recursive
                            value={element}
                            tabs={props.tabs + 1}
                            path={[...props.path, index]}
                        />
                        {index !== array.length - 1 && (
                            <span style={styles.punctuation}>, </span>
                        )}
                    </React.Fragment>
                ))}
                {!_.isEmpty(array) && <components.NewLine tabs={props.tabs} />}
                <span style={styles.punctuation}>]</span>
            </React.Fragment>
        );
    }

    return null;
}
