import * as React from 'react';
import { createContext, PropsWithChildren, useContext } from 'react';
import { Recursive, useEntry, useStack, useStyles } from './index';
import _ from 'lodash';

export type ComponentsProviderProps = PropsWithChildren<{
    components: Partial<typeof defaultComponents>;
}>;

export function ComponentsProvider(props: ComponentsProviderProps) {
    const oldComponents = useContext(ComponentsContext);
    const newComponents = { ...oldComponents, ...props.components };

    return (
        <ComponentsContext.Provider value={newComponents}>
            {props.children}
        </ComponentsContext.Provider>
    );
}

export function useComponents() {
    return useContext(ComponentsContext);
}

const defaultComponents = {
    Colon() {
        const styles = useStyles();

        return <span style={styles.punctuation}>: </span>;
    },

    Entry() {
        const components = useComponents();

        return (
            <React.Fragment>
                <components.Key />
                <components.Colon />
                <components.Value />
            </React.Fragment>
        );
    },

    Key() {
        const styles = useStyles();
        const entry = useEntry();

        return <span style={styles.key}>"{entry.key}"</span>;
    },

    NewLine(props: { tabs: number }) {
        return (
            <React.Fragment>
                <br />
                <span>{_.repeat('    ', props.tabs)}</span>
            </React.Fragment>
        );
    },

    ObjectContainer(props: PropsWithChildren<{}>) {
        const components = useComponents();
        const styles = useStyles();
        const stack = useStack();

        return (
            <React.Fragment>
                <span style={styles.punctuation}>{'{'}</span>
                {props.children}
                {!_.isEmpty(stack.value) && (
                    <components.NewLine tabs={stack.tabs} />
                )}
                <span style={styles.punctuation}>{'}'}</span>
            </React.Fragment>
        );
    },

    Value() {
        const stack = useStack();
        const entry = useEntry();

        return (
            <Recursive
                value={entry.value}
                tabs={stack.tabs + 1}
                path={[...stack.path, entry.key]}
            />
        );
    },
};

const ComponentsContext = createContext(defaultComponents);
