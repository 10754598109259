import React from 'react';
import {
    EntryProvider,
    useComponents,
    useStack,
    useStyles,
} from '@tdc-cl/react-object-renderer';
import { Failure } from 'superstruct';
import { Tooltip } from './Tooltip';
import { explain } from './utils';

export function MissingPropertyFailure(props: { failure: Failure }) {
    const components = useComponents();
    const styles = useStyles();
    const stack = useStack();

    return (
        <React.Fragment>
            <components.NewLine tabs={stack.tabs + 1} />
            <EntryProvider
                entry={{
                    key: props.failure.key,
                    value: undefined,
                    index: -1,
                }}
            >
                <components.Key />
                <components.Colon />
                <Tooltip message={explain(props.failure)}>
                    <span style={styles.missing}>{'<missing>'}</span>
                </Tooltip>
            </EntryProvider>
        </React.Fragment>
    );
}
