import * as React from 'react';
import {
    createContext,
    CSSProperties,
    PropsWithChildren,
    useContext,
} from 'react';

export type StylesProviderProps = PropsWithChildren<{
    styles: Record<string, CSSProperties>;
}>;

export function StylesProvider(props: StylesProviderProps) {
    const oldStyles = useContext(StylesContext);
    const newStyles = { ...oldStyles, ...props.styles };

    return (
        <StylesContext.Provider value={newStyles}>
            {props.children}
        </StylesContext.Provider>
    );
}

export function useStyles() {
    return useContext(StylesContext);
}

const defaultStyles = {
    container: {
        fontFamily:
            'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
        fontSize: 14,
    },
    punctuation: { color: 'black' },
    symbol: { color: 'purple', fontWeight: 600 },
    boolean: { color: 'brown', fontWeight: 600 },
    number: { color: 'blue' },
    string: { color: 'green' },
    null: { color: 'brown', fontWeight: 600 },
    undefined: { color: 'brown', fontWeight: 600 },
    key: { color: 'purple' },
    unknown: { color: 'red' },
    error: {
        textDecorationLine: 'underline',
        textDecorationStyle: 'wavy',
        textDecorationColor: 'red',
        textDecorationSkipInk: 'none',
    } as CSSProperties,
    missing: {
        fontFamily: 'sans-serif',
        color: 'gray',
        fontWeight: 400,
    } as CSSProperties,
};

const StylesContext = createContext<
    typeof defaultStyles & Record<string, CSSProperties>
>(defaultStyles);
