import * as React from 'react';
import { useStyles } from '../styles';
import { RendererProps } from '../renderers';

export function RenderNull(props: RendererProps) {
    const styles = useStyles();

    if (props.value === null) {
        return <span style={styles.null}>null</span>;
    }

    return null;
}
