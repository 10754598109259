import * as React from 'react';
import { createContext, PropsWithChildren, useContext } from 'react';
import { RenderArray } from './renderers/RenderArray';
import { RenderBoolean } from './renderers/RenderBoolean';
import { RenderNull } from './renderers/RenderNull';
import { RenderNumber } from './renderers/RenderNumber';
import { RenderObject } from './renderers/RenderObject';
import { RenderString } from './renderers/RenderString';
import { RenderUndefined } from './renderers/RenderUndefined';
import _ from 'lodash';

export interface RendererProps {
    value: unknown;
    tabs: number;
    path: Array<string | number>;
}

export type RenderersProviderProps = PropsWithChildren<{
    renderers: Record<string, React.FC<RendererProps>>;
}>;

export function RenderersProvider(props: RenderersProviderProps) {
    const oldRenderers = useContext(RenderersContext);
    const newRenderers = _.defaults({}, props.renderers, oldRenderers);

    return (
        <RenderersContext.Provider value={newRenderers}>
            {props.children}
        </RenderersContext.Provider>
    );
}

export function useRenderers() {
    return useContext(RenderersContext);
}

const defaultRenderers = {
    RenderArray,
    RenderBoolean,
    RenderNull,
    RenderNumber,
    RenderObject,
    RenderString,
    RenderUndefined,
};

const RenderersContext = createContext(defaultRenderers);
