import * as React from 'react';
import { useStyles } from '../styles';
import { RendererProps } from '../renderers';

export function RenderString(props: RendererProps) {
    const styles = useStyles();

    if (typeof props.value === 'string') {
        return <span style={styles.string}>"{props.value}"</span>;
    }

    return null;
}
