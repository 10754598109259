import {
    ComponentsProvider,
    Recursive,
    RendererProps,
} from '@tdc-cl/react-object-renderer';
import { Entry } from './Entry';
import { ObjectContainer } from './ObjectContainer';
import { StructError } from 'superstruct';
import { FailuresProvider } from './failures';
import React from 'react';

export function RenderStructError(props: RendererProps) {
    if (props.value instanceof StructError) {
        const error = props.value;

        return (
            <FailuresProvider error={error}>
                <ComponentsProvider components={{ Entry, ObjectContainer }}>
                    <span>StructError </span>
                    <Recursive
                        value={error.branch[0]}
                        tabs={props.tabs}
                        path={[]}
                    />
                </ComponentsProvider>
            </FailuresProvider>
        );
    }

    return null;
}
