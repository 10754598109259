import {
    useComponents,
    useStack,
    useStyles,
} from '@tdc-cl/react-object-renderer';
import React, { PropsWithChildren } from 'react';
import { useFailures } from './failures';
import _ from 'lodash';
import { MissingPropertyFailure } from './MissingPropertyFailure';

export function ObjectContainer(props: PropsWithChildren<{}>) {
    const components = useComponents();
    const styles = useStyles();
    const failures = useFailures();
    const stack = useStack();

    return (
        <React.Fragment>
            <span style={styles.punctuation}>{'{'}</span>
            {props.children}
            {failures
                .filter(failure => failure.value === undefined)
                .filter(failure =>
                    _.isEqual([...stack.path, failure.key], failure.path)
                )
                .map(failure => (
                    <MissingPropertyFailure
                        key={failure.key}
                        failure={failure}
                    />
                ))}
            <components.NewLine tabs={stack.tabs} />
            <span style={styles.punctuation}>{'}'}</span>
        </React.Fragment>
    );
}
