import { Failure } from 'superstruct';

export function explain(failure: Failure | undefined): string {
    if (failure == null) {
        return '';
    } else if (failure.type === 'never') {
        return `Property "${failure.key}" does not exist in object(...) struct.\nUse type(...) instead to allow additional properties.`;
    } else if (failure.value === undefined) {
        return `Missing required property "${failure.key}"`;
    } else {
        return failure.message;
    }
}
