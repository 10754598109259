import * as React from 'react';
import { createContext, useContext } from 'react';
import _ from 'lodash';
import { RendererProps, useRenderers } from './renderers';

export function Recursive(props: RendererProps) {
    const renderers = useRenderers();

    return (
        <StackContext.Provider value={props}>
            {Object.values(renderers)
                .map(r => r(props))
                .find(x => x != null)}
        </StackContext.Provider>
    );
}

export function useStack(): RendererProps {
    return useContext(StackContext);
}

const StackContext = createContext<RendererProps>(null!);
